<template>
  <div class="banner-container">
    <h3 class="header title">
      Get ready for the next Revolution
    </h3>
    <div class="form mb-10 mt-10" v-if="showForm">
      <v-row class="row-modal">
        <v-col cols="12" md="5">
          <div class="form-group">
            <p>Name*</p>
            <input v-model="name" type="text" />
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <div class="form-group">
            <p>Email*</p>
            <input v-model="email" type="text" />
          </div>
        </v-col>
        <v-col cols="12" md="2">
          <div class="submit-container" @click="submitForm()">
            <button
              class="button btn"
              :disabled="name.length === 0 && email.length === 0"
            >
              Submit
            </button>
            <div class="elipse-one"></div>
            <div class="elipse-two"></div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="button-container" @click="showFormComponent()">
      <button class="button btn">Join Waitlist</button>
      <div class="elipse-one"></div>
      <div class="elipse-two"></div>
    </div>
    <Loader :visible="isLoading"></Loader>
    <StatusModal
      :visible="showPopup"
      :status="isSuccess"
      :title="statusTitle"
      :message="statusMessage"
    />
  </div>
</template>
<script>
import Loader from "../components/Modal/Loader.vue";
import StatusModal from "../components/Modal/StatusModal.vue";
import { registerObj } from "../services/register-service";
// import { mailServiceObj } from "../services/mail-service";

export default {
  name: "Banner-Layout",
  components: { Loader, StatusModal },
  data() {
    return {
      name: "",
      email: "",
      showForm: false,
      isLoading: false,
      showPopup: false,
      statusTitle: "",
      statusMessage: "",
      isSuccess: false,
    };
  },
  methods: {
    showFormComponent() {
      this.showForm = true;
    },
    async submitForm() {
      try {
        if (this.name === "" && this.email === "") {
          this.isSuccess = false;
          this.statusTitle = "Fill the form";
          this.statusMessage = "Fill all the marked data";
          this.showPopup = true;
          setTimeout(() => {
            this.showPopup = false;
          }, 2000);
          return;
        }
        this.isLoading = true;
        const data = {
          name: this.name,
          email: this.email,
        };
        await registerObj.waitlist(data);
        // await mailServiceObj.sendNotificationEmailToSignax();
        this.isLoading = false;
        this.isSuccess = true;
        this.statusTitle = "Submission Successful";
        this.statusMessage =
          "Your waitlist request has been successfully received";
        this.showPopup = true;
        setTimeout(() => {
          this.showPopup = false;
        }, 3000);
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Submission Failed";
        this.statusMessage = error.message;
        this.showPopup = true;
        setTimeout(() => {
          this.showPopup = false;
        }, 5000);
      }
    },
  },
};
</script>
<style scoped>
.banner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3%;
}
.title {
  font-size: 50px !important;
  text-align: center;
  font-family: "Orbitron", sans-serif !important;
  width: 80%;
}
.btn {
  width: 100%;
  height: 100%;
  font-size: 22px;
  font-weight: 500;
}

.button-container {
  width: 15%;
  height: 40px;
  position: relative;
  margin-top: 20px;
}

.submit-container {
  width: 100%;
  height: 40px;
  position: relative;
  margin-top: 38px;
}

.elipse-one {
  width: 30px;
  height: 30px;
  background: #4629c6;
  filter: blur(23px);
  position: absolute;
  top: 0;
  left: 10px;
}

.elipse-two {
  background: #00f2f6;
  filter: blur(23px);
  width: 20px;
  height: 20px;
  bottom: -14px;
  right: 0;
  position: absolute;
}
.form {
  width: 80%;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease-in-out forwards;
}
.form-group {
  width: 100%;
  margin-top: 20px;
}

.form-group > p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Chakra Petch", sans-serif;
  color: #e0e0e0;
  margin: 0px;
}

.form-group > input {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.16);
  border: 1px solid #00ffbf;
  outline: none;
  color: white;
  font-family: "Chakra Petch", sans-serif;
  font-size: 12px;
  padding: 5px;
}
.row-modal {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .button-container {
    width: 55%;
    height: 40px;
    position: relative;
    margin-top: 20px;
  }
  .title {
    font-size: 42px !important;
    text-align: center;
    font-family: "Orbitron", sans-serif !important;
    width: 80%;
  }
  .banner-container{
    margin-top: 10%;
    margin-bottom: 20%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.header {
    /* background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%); */
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Orbitron', sans-serif !important;
    font-weight: 800;
}
</style>
